import { IStemdoer } from '@/shared/model/stemdoer.model';
import ResourceService from '@/shared/ResourceService.service';
import SAvatarStemdoer from '@/components/stemdo-components/s-avatar-stemdoer/SAvatarStemdoer.vue';
import SButton from '@/components/stemdo-components/s-button/SButton.vue';
import { defineComponent, inject, onMounted, ref } from 'vue';
import { useStore } from '@/plugins/vuex';
import { computed } from 'vue';
import { IRate } from '@/shared/model/rate.model';
import { useI18N } from '@/plugins/i18n';
import moment from 'moment';
import { ExperienceCollection } from '@/shared/model/experience-collection.model'; //need it when come from teamproposal
import SReadMore from '@/components/stemdo-components/s-read-more/SReadMore.vue';
import SLanguageIcon from '@/components/stemdo-components/s-language-icon/SLanguageIcon.vue';
import { StemdoerStatus } from '@/shared/model/enumerations/stemdoer-status.model';

export default defineComponent({
  props: {
    closeDialog: {
      type: Function,
      required: true,
    },
    stemdoer: {
      type: Object as () => IStemdoer,
      required: true,
    },
    addStemdoerToCart: {
      type: Function,
    },
    rates: {
      type: Array as () => IRate[],
      required: true,
    },
  },
  components: {
    SAvatarStemdoer,
    SReadMore,
    SButton,
    SLanguageIcon,
  },

  setup(props) {
    const store = useStore();
    const i18n = useI18N();
    const stemdoerMutable = ref<IStemdoer>(Object.assign({}, props.stemdoer));
    const resourceService: ResourceService = inject('resourceService');
    const descriptionArray = ref([]);
    const loading = ref(false);
    const cart = computed(() => store.getters['cartStore/cartItems']);
    const clientSelected = store.getters['cartStore/clientSelected'];
    const stemdoerisInCart = computed(() => {
      return cart.value.some(item => item.id === props.stemdoer['id']);
    });

    onMounted(() => {
      document.documentElement.style.overflow = 'hidden';
      if (!stemdoerMutable.value.experience) {
        return;
      } else {
        const experienceCollection = new ExperienceCollection(stemdoerMutable.value.experience);
        stemdoerMutable.value.experience = experienceCollection.groupByCompany();
      }
    });

    const handleAddToCartBtn = () => {
      const stemdoerWithAvailability = props.stemdoer as { availability: { available: boolean } };
      if (stemdoerWithAvailability.availability.available) {
        props.addStemdoerToCart(props.stemdoer);
      }
      props.closeDialog();
    };

    const getRate = (seniority: string) => {
      let rate = 36;
      let ratesSource: any[] = [];
      if (props.rates && props.rates.length > 0) {
        ratesSource = props.rates;
      } else if (clientSelected && store.getters.account?.authorities.includes('ROLE_GESTOR')) {
        ratesSource = clientSelected.clientRate?.rates || [];
      }
      if (Array.isArray(ratesSource)) {
        ratesSource.forEach(item => {
          if (item.seniority.toLowerCase().replace(/[-_]/g, '') == seniority.toLowerCase().replace(/[-_]/g, '')) {
            rate = item.value;
          }
        });
      }
      return rate;
    };

    const getStarSkills = (skills: number) => {
      const skillLevel = skills;
      if (skillLevel <= 3) {
        return (
          '<img src="/content/svgs/star.svg" />' +
          '<img src="/content/svgs/star-outline.svg" />' +
          '<img src="/content/svgs/star-outline.svg" />'
        );
      } else if (skillLevel <= 5) {
        return (
          '<img src="/content/svgs/star.svg" />' + '<img src="/content/svgs/star.svg" />' + '<img src="/content/svgs/star-outline.svg" />'
        );
      } else if (skillLevel >= 6) {
        return '<img src="/content/svgs/star.svg" />' + '<img src="/content/svgs/star.svg" />' + '<img src="/content/svgs/star.svg" />';
      }
    };

    const getInfoBadge = (skills: number, id: number) => {
      let translationKey;
      if (skills <= 3) {
        translationKey = 'explore.stemdoer-detail.tech-badges.' + id + '.1';
      } else if (skills <= 5) {
        translationKey = 'explore.stemdoer-detail.tech-badges.' + id + '.2';
      } else if (skills >= 6) {
        translationKey = 'explore.stemdoer-detail.tech-badges.' + id + '.3';
      }
      let translation = i18n.t(translationKey);
      if (translation === translationKey) {
        translation = i18n.t(translationKey.replace(id.toString(), '0'));
      }
      return translation;
    };

    const getTitleBadge = (skills: number) => {
      if (skills <= 3) {
        return i18n.t('explore.stemdoer-detail.tech-badges.tier.1');
      } else if (skills <= 5) {
        return i18n.t('explore.stemdoer-detail.tech-badges.tier.2');
      } else if (skills >= 6) {
        return i18n.t('explore.stemdoer-detail.tech-badges.tier.3');
      }
    };

    const formatDate = dateString => {
      if (dateString != null) {
        const deTranslation = store.getters.currentLanguage == 'es' ? 'de' : 'of';
        const date = moment(dateString);
        const formattedDate = date.locale(store.getters.currentLanguage).format(`MMMM [${deTranslation}] YYYY`);
        return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
      } else {
        return null;
      }
    };

    const getTech = (techs, limit?) => {
      if (techs && techs.includes(';')) {
        const techList = techs.split(';').map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        });
        if (limit === undefined) return techList.slice(0, limit);
        return techList;
      } else {
        return [];
      }
    };

    const getTotalTime = time => {
      const years = Math.floor(time / 12);
      const months = time % 12;
      let totalTime = '';
      if (years !== 0) {
        totalTime += years + ' ' + (years > 1 ? i18n.t('explore.stemdoer-detail.years') : i18n.t('explore.stemdoer-detail.year')) + ' ';
      }
      if (months !== 0) {
        totalTime += months + ' ' + (months > 1 ? i18n.t('explore.stemdoer-detail.months') : i18n.t('explore.stemdoer-detail.month')) + ' ';
      }
      return totalTime.trim();
    };

    const startLoading = () => {
      loading.value = true;
      generatePDF();
      setTimeout(() => {
        loading.value = false;
      }, 5000);
    };

    const generatePDF = () => {
      const myData = {
        locale: store.getters.currentLanguage,
      };
      const myJSON = JSON.stringify(myData);
      resourceService.generatePDF(props.stemdoer['id'], myJSON);
    };

    const isStemdoExp = company => {
      if (company == null) return false;
      return company.toLowerCase() == 'stemdo';
    };

    const sectorFormatted = (text: string | null | undefined) => {
      if (text == null) {
        return '';
      }
      return `(${text.toUpperCase()})`;
    };

    const shouldDisplayEnglishBadge = (english: string | undefined) => {
      return english != 'A1' && english != 'A2' && english != '' && english != undefined;
    };

    return {
      stemdoerMutable,
      descriptionArray,
      loading,
      stemdoerisInCart,
      StemdoerStatus,
      sectorFormatted,
      getInfoBadge,
      getStarSkills,
      formatDate,
      getTech,
      startLoading,
      isStemdoExp,
      getRate,
      getTotalTime,
      getTitleBadge,
      handleAddToCartBtn,
      shouldDisplayEnglishBadge,
    };
  },
});
