var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "px-2", attrs: { id: "requests-detail" } },
    [
      _vm.stepperStatus() != 5
        ? _c(
            "v-stepper",
            {
              staticClass: "my-stepper mb-5",
              attrs: { elevation: "0" },
              model: {
                value: _vm.activeStep,
                callback: function ($$v) {
                  _vm.activeStep = $$v
                },
                expression: "activeStep",
              },
            },
            [
              _c(
                "v-stepper-header",
                [
                  _c(
                    "v-stepper-step",
                    { staticClass: "my-stepper-step", attrs: { step: "📬​" } },
                    [
                      _c("div", { staticClass: "step-label text-body-3" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("requests.detail.status.pending")) +
                            "\n        "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-divider", {
                    staticClass: "my-divider1",
                    style: {
                      border:
                        _vm.stepperStatus() == 1 || _vm.stepperStatus() == 2
                          ? "2px #8859FF solid"
                          : "2px #ECECEF solid",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "v-stepper-step",
                    {
                      staticClass: "my-stepper-step",
                      class: {
                        grayscale:
                          _vm.stepperStatus() != 1 && _vm.stepperStatus() != 2,
                      },
                      attrs: { step: "📪​" },
                    },
                    [
                      _c("div", { staticClass: "step-label text-body-3" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("requests.detail.status.validated")) +
                            "\n        "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-divider", {
                    staticClass: "my-divider2",
                    style: {
                      border:
                        _vm.stepperStatus() == 2
                          ? "2px #8859FF solid"
                          : "2px #ECECEF solid",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "v-stepper-step",
                    {
                      staticClass: "my-stepper-step",
                      class: { grayscale: _vm.stepperStatus() != 2 },
                      attrs: { step: "📫​​" },
                    },
                    [
                      _c("div", { staticClass: "step-label text-body-3" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("requests.detail.status.finished")) +
                            "\n        "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "mt-6 pa-4",
              staticStyle: {
                border: "1px solid #dbdbe074",
                "border-radius": "3px",
                width: "100%",
                "background-color": "#f9f9f9",
              },
              attrs: { cols: "12" },
            },
            [
              _c("div", { staticClass: "msg-title text-body-2-bold pb-2" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("requests.detail.status.title")) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "msg-txt-status text-body-1" },
                [
                  _c("TeamRequestStatus", {
                    attrs: {
                      teamRequest: _vm.requestSelected,
                      closeRequestDetailModal: _vm.closeRequestDetailModal,
                      refreshTeamRequestData: _vm.refreshTeamRequestData,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "mt-6 pa-4",
              staticStyle: {
                border: "1px solid #dbdbe074",
                "border-radius": "3px",
                width: "100%",
              },
              attrs: { cols: "12" },
            },
            [
              _c("div", { staticClass: "team-naming" }, [
                _c(
                  "div",
                  {
                    staticClass: "msg-subtitle text-body-2-bold",
                    staticStyle: { color: "#606061" },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("requests.detail.team-name")) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "text-body-1 pt-1" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.requestSelected.name) +
                      "\n        "
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "mt-6 pa-4",
              staticStyle: {
                border: "1px solid #dbdbe074",
                "border-radius": "3px",
                width: "100%",
              },
              attrs: { cols: "12" },
            },
            [
              _c("div", { staticClass: "team-naming" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between align-items-center",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "msg-subtitle text-body-2-bold" },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("requests.detail.my-team")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("v-row", { staticClass: "d-flex" }, [
                          _c(
                            "div",
                            { staticClass: "toolBoxTop mr-3" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btnPDF",
                                  staticStyle: {
                                    "margin-top": "-0.2em",
                                    "background-color":
                                      "rgba(255, 255, 255, 0) !important",
                                  },
                                  attrs: {
                                    loading: _vm.loading,
                                    disabled: _vm.loading,
                                    fab: "",
                                  },
                                  on: {
                                    click: () => {
                                      _vm.loading = true
                                      _vm.startLoading()
                                    },
                                  },
                                },
                                [
                                  _c("v-img", {
                                    staticClass: "pdf-svg",
                                    attrs: {
                                      src: "/content/svgs/pdf-button.svg",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "pdf",
                                  attrs: {
                                    loading: _vm.loading,
                                    disabled: _vm.loading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.loading = true
                                      _vm.startLoading()
                                    },
                                  },
                                },
                                [_vm._v("PDF")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "text-body-1" }, [
                  _vm.requestSelected
                    ? _c(
                        "div",
                        { staticClass: "chip-container pt-3" },
                        [
                          _vm._l(
                            _vm.requestSelected.stemdoerRates,
                            function (stemdoerRate, index) {
                              return [
                                index < 9
                                  ? _c(
                                      "v-chip",
                                      {
                                        key: "chip-rate-" + index,
                                        staticClass:
                                          "overlapping-chip no-padding",
                                        attrs: { color: "transparent" },
                                      },
                                      [
                                        _c("SAvatarStemdoer", {
                                          staticClass: "avatar",
                                          attrs: {
                                            stemdoId: stemdoerRate.stemdoId,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : index === 9
                                  ? _c(
                                      "v-chip",
                                      {
                                        key: "chip-rate-" + index,
                                        staticClass:
                                          "overlapping-chip no-padding",
                                        attrs: { color: "transparent" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                +" +
                                            _vm._s(
                                              _vm.requestSelected.stemdoerRates
                                                .length - 9
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mt-9 mb-6",
          staticStyle: { "justify-content": "space-between" },
        },
        [
          _c(
            "div",
            {
              staticClass: "box-design-cost pa-4",
              staticStyle: {
                border: "1px solid #dbdbe074",
                "border-radius": "3px",
              },
            },
            [
              _c(
                "h3",
                {
                  staticClass: "msg-subtitle",
                  staticStyle: { margin: "0px !important", padding: "0px" },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("requests.detail.daily-rate")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("h3", { staticClass: "msg-cost py-2" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      "≈" +
                        _vm.$n(_vm.requestSelected.hourlyRate * 8, "currency")
                    ) +
                    "\n      "
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "box-design-cost pa-4",
              staticStyle: {
                border: "1px solid #dbdbe074",
                "border-radius": "3px",
              },
            },
            [
              _c(
                "h3",
                {
                  staticClass: "msg-subtitle",
                  staticStyle: { margin: "0px !important", padding: "0px" },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("requests.detail.monthly-rate")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("h3", { staticClass: "msg-cost py-2" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      "≈" +
                        _vm.$n(
                          _vm.requestSelected.hourlyRate * 8 * 20,
                          "currency"
                        )
                    ) +
                    "\n      "
                ),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mt-5 mb-6",
          staticStyle: { "justify-content": "space-between" },
        },
        [
          _c("div", { staticClass: "box-design-date" }, [
            _c(
              "div",
              {
                staticClass: "pa-3",
                staticStyle: {
                  border: "1px solid #dbdbe074",
                  "border-radius": "3px",
                  width: "100%",
                },
              },
              [
                _c(
                  "h3",
                  {
                    staticClass: "msg-subtitle text-body-2-bold",
                    staticStyle: { "font-weight": "500", "font-size": "14px" },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("requests.detail.init-date")) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "h3",
                  {
                    staticClass: "msg-date",
                    staticStyle: { color: "#222226" },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.requestSelected.initDate
                            ? _vm.$d(
                                Date.parse(_vm.requestSelected.initDate),
                                "twoDigit"
                              )
                            : _vm.$t("requests.detail.undefined")
                        ) +
                        "\n        "
                    ),
                  ]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "box-design-date" }, [
            _c(
              "div",
              {
                staticClass: "pa-3",
                staticStyle: {
                  border: "1px solid #dbdbe074",
                  "border-radius": "3px",
                  width: "100%",
                },
              },
              [
                _c(
                  "h3",
                  {
                    staticClass: "msg-subtitle text-body-2-bold",
                    staticStyle: { "font-weight": "500", "font-size": "14px" },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("requests.detail.end-date")) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "h3",
                  {
                    staticClass: "msg-date",
                    staticStyle: { color: "#222226" },
                  },
                  [
                    _vm.requestSelected.endDate
                      ? [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$d(
                                  Date.parse(_vm.requestSelected.endDate),
                                  "twoDigit"
                                )
                              ) +
                              "\n          "
                          ),
                        ]
                      : [
                          _c(
                            "v-tooltip",
                            {
                              attrs: {
                                bottom: "",
                                "content-class":
                                  "background-darken3 tooltip-bottom",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "span",
                                        _vm._g(
                                          _vm._b({}, "span", attrs, false),
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$d(
                                                  new Date(
                                                    new Date().getFullYear(),
                                                    11,
                                                    31
                                                  ),
                                                  "twoDigit"
                                                )
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _vm._v(" "),
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("requests.detail.tentative-date")
                                  ),
                                },
                              }),
                            ]
                          ),
                        ],
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "mt-2 mb-6",
              staticStyle: {
                border: "1px solid #dbdbe074",
                "border-radius": "3px",
                width: "100%",
              },
              attrs: { cols: "12" },
            },
            [
              _c("h3", { staticClass: "msg-subtitle text-body-2-bold" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("requests.detail.observations")) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "text-body-1" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.requestSelected.jobDescription) +
                    "\n      "
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }